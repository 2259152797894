<template>
    <div id="pageList" class="SaleRentCarList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">名称：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车辆能源类型：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入车辆能源类型进行模糊匹配查询..." v-model="pageList.queryParam.type" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
<!--                <el-table-column prop="F_FLAG" label="类别"/>-->
                <el-table-column prop="F_NAME" label="名称"/>
                <el-table-column prop="F_TYPE" label="车辆能源类型"/>
                <el-table-column prop="F_RENT_PRICE" label="租车价"/>
                <el-table-column prop="F_BUY_PRICE" label="买车价"/>
                <el-table-column prop="F_STATUS" label="停用/启用状态"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import SaleRentCarCard from './BuyRentCarCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "SaleRentCarList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    queryParam: {
                       flag:"", 
                       name:"", 
                       type:"" 
                    },
                    modelComp: SaleRentCarCard,
                    modelMethod: "/buyRentCar/pageData"
               }
            })
            onMounted(()=>{
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'买车'},{value:1,label:'租车'}]
                    }
                }
            })

            return{
                ...toRefs(dataObj),comboSelect
            }
        }
    });
</script>

<style scoped>
    .SaleRentCarList{
        width: 100%;
    }
</style>